import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import { address0 } from '~/consts';
import { GetPagedStreamsDocument, GetStreamByIdDocument, OrderDirection as GraphOrderDirection, Stream_OrderBy, } from '~/generated/gql/network';
import { getGraphClient } from '~/getters/getGraphClient';
const calculatePubSubCount = (permissions) => {
    let publisherCount = 0;
    let subscriberCount = 0;
    permissions.forEach((perm) => {
        // If stream has public permissions (zero address), return null for counts which means that anyone can
        // publish or subscribe
        if (perm.userId === address0) {
            if (perm.subscribeExpiration >= Math.round(Date.now() / 1000)) {
                subscriberCount = null;
            }
            if (perm.publishExpiration >= Math.round(Date.now() / 1000)) {
                publisherCount = null;
            }
        }
        if (perm.subscribeExpiration >= Math.round(Date.now() / 1000) &&
            subscriberCount != null) {
            subscriberCount += 1;
        }
        if (perm.publishExpiration >= Math.round(Date.now() / 1000) &&
            publisherCount != null) {
            publisherCount += 1;
        }
    });
    return {
        publisherCount,
        subscriberCount,
    };
};
const mapStream = (stream) => {
    const result = {
        id: stream.id,
        publisherCount: 0,
        subscriberCount: 0,
        permissions: stream.permissions,
    };
    // Get publisher and subscriber counts
    const counts = calculatePubSubCount(stream.permissions || []);
    result.publisherCount = counts.publisherCount;
    result.subscriberCount = counts.subscriberCount;
    // Try to parse metadata JSON
    if (stream.metadata != null) {
        try {
            result.metadata = JSON.parse(stream.metadata);
        }
        catch (e) {
            console.error(`Could not parse metadata for stream ${stream.id}`, e);
            result.metadata = {};
        }
    }
    return result;
};
const prepareStreamResult = (result, pageSize) => {
    let hasNextPage = false;
    const streams = result.map((p) => mapStream(p));
    if (streams.length > pageSize) {
        hasNextPage = true;
        // Remove last item
        streams.splice(pageSize, 1);
    }
    return {
        streams,
        hasNextPage,
        lastId: streams[streams.length - 1]?.id,
    };
};
export const getPagedStreams = async (chainId, first, lastId, owner, search, orderBy = Stream_OrderBy.Id, orderDirection = 'asc', { force = false } = {}) => {
    const orderOperator = orderDirection === 'asc' ? 'gt' : 'lt';
    const graphOrderDirection = orderDirection === 'asc' ? GraphOrderDirection.Asc : GraphOrderDirection.Desc;
    let where = {
        permissions_: {
            stream_contains_nocase: search,
            userId: owner,
        },
        [`id_${orderOperator}`]: lastId,
    };
    where.permissions_ = omitBy(where.permissions_, isEmpty);
    where = omitBy(where, isEmpty);
    const { data: { streams }, } = await getGraphClient(chainId).query({
        query: GetPagedStreamsDocument,
        variables: {
            first: first + 1,
            orderBy,
            orderDirection: graphOrderDirection,
            where,
        },
        fetchPolicy: force ? 'network-only' : void 0,
    });
    if (streams && streams.length > 0) {
        return prepareStreamResult(streams.map((s) => ({
            ...s,
            permissions: s.permissions?.map((p) => ({
                ...p,
                userAddress: p.userId, // We need to add this deprecated field for now
            })),
        })), first);
    }
    return {
        streams: [],
        hasNextPage: false,
        lastId: null,
    };
};
export const getStreamsOwnedBy = async (chainId, owner, search, onlyPublic, { force = false } = {}) => {
    const allOwnedStreams = await getPagedStreams(chainId, 999, undefined, owner, search, Stream_OrderBy.Id, 'asc', { force });
    let result = allOwnedStreams.streams;
    if (onlyPublic) {
        result = result.filter((s) => s.permissions.find((p) => p.userId.toLowerCase() === address0.toLowerCase()) != null);
    }
    return result;
};
export const checkIfStreamExists = async (chainId, potentialStreamId) => {
    const { data: { stream }, } = await getGraphClient(chainId).query({
        query: GetStreamByIdDocument,
        variables: {
            streamId: potentialStreamId,
        },
        fetchPolicy: 'network-only',
    });
    return !!stream;
};
