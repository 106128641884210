import { getIndexerClient } from '~/getters/getGraphClient';
import { GetNeighborsDocument, } from '../generated/gql/indexer';
export async function getNeighbors(params) {
    const pageSize = 1000;
    const { node, streamId, streamPartitionId, chainId } = params;
    const items = [];
    const uniquenessGate = {};
    let cursor = '0';
    for (;;) {
        const client = getIndexerClient(chainId);
        if (!client) {
            console.error('Could not get indexer client for chainId', chainId);
            break;
        }
        const { data: { neighbors }, } = await client.query({
            fetchPolicy: 'network-only',
            query: GetNeighborsDocument,
            variables: {
                cursor,
                node,
                pageSize,
                streamId,
                streamPart: streamPartitionId,
            },
        });
        for (const { nodeId1: a, nodeId2: b, streamPartId: finalStreamPartitionId, rtt, } of neighbors.items) {
            const pair = [a, b].sort();
            const key = pair.join('-');
            if (uniquenessGate[key]) {
                continue;
            }
            uniquenessGate[key] = true;
            const [nodeId0, nodeId1] = pair;
            items.push({
                nodeId0,
                nodeId1,
                streamPartitionId: finalStreamPartitionId,
                rtt: rtt ?? undefined,
            });
        }
        if (!neighbors.cursor || neighbors.cursor === cursor) {
            break;
        }
        cursor = neighbors.cursor;
    }
    return items;
}
